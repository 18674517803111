import { useState, useEffect, FormEvent, ChangeEvent, useRef} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { useParams } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';
import { CredentialAndDomain } from '../DTO/CredentialAndDomain';
import { AuthenticationDto } from '../DTO/AuthenticationDto';
import { AuthenticateResponseDto } from '../DTO/AuthenticateResponseDto';
import { AuthenticationStatusDto } from '../DTO/AuthenticationStatusDto';
import { InteractionFieldSetDto } from '../DTO/InteractionFieldSetDto';

import moment from 'moment'



const Page_AuthenticationDiagnostics = () => {
    let { id } = useParams(); 
    let { domain } = useParams(); 
    const context = useIonScreenAuth();

    //const [time, setTime] = useState(0); // Timer in seconds
    ///const [isRunning, setIsRunning] = useState(false);

    const interactionValue = useRef<string>('');
    const timerForRereshingData = useRef<NodeJS.Timeout|null>(null);
    const refreshes = useRef<number>(0);

    const [authenticateResponseDto, setAuthenticateResponseDto] = useState<AuthenticateResponseDto|null>(null);
    const authenticateResponseDtoRef = useRef<AuthenticateResponseDto|null>(null);
    const [ionScreenCredentialAndDomain, setIonScreenCredentialAndDomain] = useState<CredentialAndDomain|null>(null);

    const [imageBase64, setImageBase64] = useState<string>('');
    
    const [authenticationStatusDto, setAuthenticationStatusDto] = useState<AuthenticationStatusDto|null>(null);
  
      useEffect(() => {
        
        const abortController = new AbortController();
        getAuthenticationInfoAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

      

    const getAuthenticationInfoAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            axiosRequestConfig.params = {"credentialId":id, "domain": domain}

            var result = await axios.get<CredentialAndDomain>(config.backEndAppApi + `/api/GetCredentialDataForDomain`, axiosRequestConfig);
            setIonScreenCredentialAndDomain(result.data);
        }
        catch {

        }
    }

    const HandleInteractionValueChange = (event:ChangeEvent<HTMLInputElement>) => {
        interactionValue.current = event.currentTarget.value;
    }

    
  

    const HandleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);

            let authenticationDto:AuthenticationDto = {credentialId:ionScreenCredentialAndDomain!.credentialId, domain:ionScreenCredentialAndDomain!.domain};
            var responseDto= await axios.post<AuthenticateResponseDto>(config.backEndAppApi + `/api/Authenticate`, authenticationDto, axiosRequestConfig);
            
            setAuthenticateResponseDto((dummy)=>  responseDto?.data);
            authenticateResponseDtoRef.current = responseDto?.data; // I don't understand that closure, let's move on

            if (timerForRereshingData.current != null){
                clearInterval(timerForRereshingData.current);
            }
            refreshes.current = 0;
            timerForRereshingData.current = setInterval(
                () => tickForRefresh(),
                5000
              );

        }
        catch(error) {
            console.log(error);
        }
    }

    const HandleSendInteractionClick   = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);

            let interactionFieldSetDto:InteractionFieldSetDto = {messageId: authenticateResponseDtoRef.current!.messageId, interactionField:authenticationStatusDto!.interactionField, interactionValue:interactionValue.current};
            var responseDto= await axios.post<InteractionFieldSetDto>(config.backEndAppApi + `/api/PostAuthenticationInteractionField`, interactionFieldSetDto, axiosRequestConfig);
            
        }
        catch(error) {
            console.log(error);
        }
    }

    const tickForRefresh= async() =>{
        refreshes.current++; 

        try {

            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"messageId":authenticateResponseDtoRef.current?.messageId}
            var interactionResponse= await axios.get<AuthenticationStatusDto>(config.backEndAppApi + `/api/GetAuthenticationStatus`, axiosRequestConfig);

            setAuthenticationStatusDto(interactionResponse.data);
            //TODOOOO
            //setInteractionValues(interactionResponse.data)


            //get the image
            axiosRequestConfig.validateStatus= status => (status >= 200 && status < 300) || status === 404
            axiosRequestConfig.params = {"sessionId":authenticateResponseDtoRef.current?.sessionId, "playListDefinitionId":authenticateResponseDtoRef.current?.screenId}
            axiosRequestConfig.responseType= 'arraybuffer'
            //axiosRequestConfig.responseEncoding = 'binary'

            var response= await axios.get(config.backEndAppApi + `/api/TrackAuthentication`, axiosRequestConfig);

            if (response.status === 200) {
                  let imageData = btoa(
                    new Uint8Array(response.data)
                      .reduce((data, byte) => data + String.fromCharCode(byte), '')
                  );
                  
                  setImageBase64(`data:${response.headers['content-type'].toLowerCase()};base64,${imageData}`);
            }


        }
        catch(error) {
            console.log(error);
        }

        if (refreshes.current > 100 && timerForRereshingData.current != null){
            clearInterval(timerForRereshingData.current);
        }
      }

      function GetTimeString(date:Date): string {
              if (date === null) {
                  return '?';
              }
              var localDate = moment(date).local();
              return localDate.format("YYYY-MM-DD HH:mm:ss");
          }


    
        if (ionScreenCredentialAndDomain===null) {
            return (<LoadingComponent/>);
        }
    
             
        return (
            <>
              <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CredentialId</td>
                        <td>{ionScreenCredentialAndDomain.credentialId}</td>
                    </tr>

                    <tr>
                        <td>Username</td>
                        <td>{ionScreenCredentialAndDomain.userName}</td>
                    </tr>

                    <tr>
                        <td>Credential description</td>
                        <td>{ionScreenCredentialAndDomain.description}</td>
                    </tr>

                    <tr>
                        <td>Domain</td>
                        <td>{ionScreenCredentialAndDomain.domain}</td>
                    </tr>

                    <tr>
                        <td>Last authentication attempt</td>
                        <td>{GetTimeString(ionScreenCredentialAndDomain.lastAuthenticationAttemptDateTime)}</td>
                    </tr>

                    <tr>
                        <td>Last authentication result</td>
                        <td>{ionScreenCredentialAndDomain.lastAuthenticationAttemptResult===null?"?":(ionScreenCredentialAndDomain.lastAuthenticationAttemptResult=== true?"success":"no success")}</td>
                    </tr>
             
            </tbody>
            </table>

            <br/>
            <br/>
            <div><button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleClick}>Authenticate</button></div>
            <br/>
            {(authenticateResponseDto !== null) ?<>
                              <div>{authenticateResponseDto.sessionId}</div>
                              <div>{authenticateResponseDto.screenId}</div>
                          </> :  <></>}

            <br></br>
            { (authenticationStatusDto !== null && authenticationStatusDto?.interactionField !== null && authenticationStatusDto?.interactionField !== '') ? <div>{authenticationStatusDto?.interactionField}<input className='rounded border' onChange={HandleInteractionValueChange}></input><button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleSendInteractionClick}>SendInteraction</button></div>: <></>}

            
              {(imageBase64!== '')? <img src={imageBase64} alt=""/>:<></>}           
             </>
        );
}

export default Page_AuthenticationDiagnostics;



