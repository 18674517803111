import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';



const Page_Home = () => {
    
    const context = useIonScreenAuth();
    const navigate = useNavigate();

    
    const HandleTestNotifClicked= async() =>{
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            await axios.get(config.backEndAppApi + `/api/Send`, axiosRequestConfig);
        }
        catch(error) {
            console.log(error);
        }
    }
    const HandleClearClicked= async() =>{
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            await axios.get(config.backEndAppApi + `/api/Clear`, axiosRequestConfig);

            const registration = await navigator.serviceWorker.ready;

            let sub = await registration.pushManager.getSubscription();
            if (sub !== null) {
                sub.unsubscribe();
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    const HandleSubClearClicked= async() =>{
        try {
            const registration = await navigator.serviceWorker.ready;

            let sub = await registration.pushManager.getSubscription();
            if (sub !== null) {
                sub.unsubscribe();
            }
        }
        catch(error) {
            console.log(error);
        }
    }

 
    
        
    
    
    return(
        <>
            Welcome {context.userName} to the IonScreen application.
            <br/>
            <br/>
            Get started by
            <ul className='list-disc ml-8'>
                  <li>Registering your credentials.</li>
                  <li>Creating new playlist items.</li>
                  <li>Combining these items to a playlist.</li>
                  <li>Registering a display.</li>
                  <li>And assigning a playlist to a display.</li>
                  <li>Then test using <a href="https://play.ionscreen.online" target="_blank" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">https://play.ionscreen.online</a> to view the results. You need to link the display device to the a display first using the registration code.</li>
                  <li>Everything looking good: get your streaming device!</li>
            </ul>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleTestNotifClicked}>Test notif</button>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleClearClicked}>Clear notif</button>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleSubClearClicked}>Clear sub</button>
        </>
        );
}

export default Page_Home;
