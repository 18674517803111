import { useState, useEffect, MouseEventHandler, useRef, ChangeEvent} from 'react';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import config from '../Config';
import { CredentialAndDomain } from '../DTO/CredentialAndDomain';
import { Console } from 'console';
import { AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'; // todo make central
import { useMsal } from "@azure/msal-react";
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import LoadingComponent from './LoadingComponent';
import { AuthenticationDto } from '../DTO/AuthenticationDto';
import { Link, useNavigate } from 'react-router-dom';

const Page_Authentication = () => {
    const [credentials, setcredentials] = useState<CredentialAndDomain[]>([]);
    const context = useIonScreenAuth();
    const [loading,setLoading] = useState(true)
   
    useEffect(() => {
        const abortController = new AbortController();
        getCredentialsAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getCredentialsAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            var result = await axios.get<CredentialAndDomain[]>(config.backEndAppApi + `/api/GetCredentialsAndDomains`, axiosRequestConfig);
            setcredentials(result.data);
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }
    
  
    if (loading) {
        return (<LoadingComponent/>);
    }

    return(
        <div>
            <h1 className='underline text-xl'>MFA Authentication</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Credential Id</th>
                        <th>Description</th>
                        <th>UserName</th>
                        <th>Domain</th>
                        <th>Healthy</th>
                    </tr>
                </thead>
                <tbody>
             {credentials.map((d, index) => (
             <tr key={`${d.credentialId}${d.domain}`}>
                <td><Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`/credentialauth/${d.credentialId}/${d.domain}`}>{d.credentialId}</Link></td>
                <td>{d.description}</td>
                <td>{d.userName}</td>
                <td>{d.domain}</td>
                <td>&nbsp;&nbsp;&nbsp;{d.lastAuthenticationAttemptResult===null?"?":(d.lastAuthenticationAttemptResult=== true?"yes":"no")}</td>
            </tr>))} 

             </tbody>
            </table>
        </div>
        );
}

export default Page_Authentication;

